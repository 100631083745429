import { Route } from '@angular/router';
import { authenticated, unauthenticated } from 'auth';
import { hasPin } from './modules/profile/store/profile.guards';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [unauthenticated('/pin')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pin',
    canActivate: [authenticated('/')],
    loadChildren: () =>
      import('./modules/pin/pin.module').then((m) => m.PinModule),
  },
  {
    path: 'profile',
    canActivate: [authenticated('/'), hasPin('/pin')],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  { path: '**', redirectTo: '' },
];
